import React, { useState,useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import parse from 'html-react-parser'

export default function PrivacyPolicy({ ppIsOpen, setPpIsOpen }) {
    const [content,setContent] = useState(null)

    function toggleModal() {
        setPpIsOpen(!ppIsOpen);
    }

    useEffect(()=>{
        axios.post(process.env.REACT_APP_API_URL + "/landingpagecontent/privacy-policy", {sitename:"CutCreditCardDebt.Us"})
            .then(function (response) {
                setContent(response.data.data.html);
            })
    },[])

    const customStyles = {
        content: {
          width: '90%',
          height: '70%',
        },
      };

    return (
        <>
            <a href="javascript:void();" onClick={toggleModal}>Privacy Policy</a>
            <Modal
                isOpen={ppIsOpen}
                onRequestClose={toggleModal}
                contentLabel="Privacy policy"
                className="contentModal"
                overlayClassName="contentModalOverlay"
                ariaHideApp={false}
                style={customStyles}
                
            >
                <div className="contentModalHeader">
                    <div className="row mt-2">
                        <div className="col">
                            <h3>PRIVACY POLICY</h3>
                        </div>
                        <div className="col-auto text-right">
                            <a className="pointer-cursor text-white" onClick={toggleModal}>Close</a>
                        </div>
                    </div>
                </div>
                <div className="contentModalText">
                    {content &&
                        parse(content)
                    }
                </div>
            </Modal>
        </>
    );
}
