import React, {useEffect} from "react";
import '../../assets/css/bootstrap-slider.css';
import ReactBootstrapSlider from 'react-bootstrap-slider';
import CurrencyFormat from 'react-currency-format';
import ReactGA from 'react-ga4';


const debtarray = [
    4500, 
    7500, 
    12500, 
    17500, 
    22500, 
    27500, 
    35000, 
    45000,
    55000,
    67500,
    75000
];

const debtrange = [
    "Under 5K", 
    "$5,000 - $9,999", 
    "$10,000 - $14,999", 
    "$15,000 - $19,999", 
    "$20,000 - $24,999", 
    "$25,000 - $29,999", 
    "$30,000 - $39,999", 
    "$40,000 - $49,999",
    "$50,000 - $59,999",
    "$60,000 - $74,999",
    "$75000+"
];
const MIN = 0;
const MAX = debtarray.length - 1;

const DebtAmount = (props) => {

    useEffect(()=>{

        if (!props.values.creditcarddebt) {
            props.manualHandleChange('creditcarddebt',"4")
        }

        window.runeHawkTalon();

        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: "pageview", page: "/debt-amount" });

        ReactGA.event({
            category: 'CCCD Landing',
            action: 'CCCD Landing',
            label: 'CCCD Landing'
        });
    },[])

    return (
        <form>
            <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
            <div id="section-debt-amount" className="section-question section-show">
                <div className="row question">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 text-center">
                        <p>Approximate Credit Card Debt</p>
                    </div>
                </div>

                <div className="row mb-2 mt-3">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 text-center">
                        <div id="debt-amount-value" className="price-value">
                            <div>{debtrange[props.values.creditcarddebt]}</div>
                            {/* <CurrencyFormat value={props.values.creditcarddebt} displayType={'text'} thousandSeparator={true} prefix={'$'} /> */}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <div className="property-price-value">
                            <ReactBootstrapSlider
                                max={MAX}
                                min={MIN}
                                orientation="horizontal"
                                className="slider"
                                tooltip="hide"
                                value={props.values.creditcarddebt}
                                change={(e)=>props.manualHandleChange('creditcarddebt',e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <span className="range-min">Under 5K</span><span className="range-max">$75,000</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 text-center mt-3 mb-3">
                        <button type="button" id="btnsubmit-debt-amount" className="btn-block btn btn-primary btn-lg btn-text-white btn-350" onClick={props.next}>Next &gt;</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default DebtAmount;
