import React from "react";

export default function TermsOfUse() {
  return (
    <a
      href="https://www.folderleafsix.com/o-qkbh-n94-ae784ea6f28780b7c643f58d2dd242ff"
      target="_blank"
    >
      Opt Out
    </a>
  );
}
