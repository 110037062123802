import React, {useEffect} from "react";
import { useForm } from "react-hook-form";
import ReactGA from 'react-ga4';

const Name = (props) => {
    
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => props.next();

    useEffect(()=>{
        window.runeHawkTalon();

        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: "pageview", page: "/name" });

        ReactGA.event({
            category: 'Step 4, Name',
            action: 'Step 4, Name',
            label: 'Step 4, Name'
        });
    },[])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
            <div id="section-name" className="section-question">
                <div className="row question">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 text-center">
                        <p>What's your name?</p>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 input-holder">
                        <input type="text" className="form-control" maxLength="50"
                               placeholder="First Name" autoComplete="off"
                               value={ props.values.firstname }
                               {...register("firstname", { required: true, pattern: /^[A-Za-z- ]+$/i })}
                               onChange={(e)=>props.manualHandleChange('firstname',e.target.value)}/>
                        {errors.firstname && errors.firstname.type === "required" && <span className="text-danger">Enter your first name</span>}
                        {errors.firstname && errors.firstname.type === "pattern" && <span className="text-danger">Only letters</span>}
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 input-holder">
                        <input type="text" className="form-control" maxLength="50"
                               placeholder="Last Name" autoComplete="off"
                               value={ props.values.lastname }
                               {...register("lastname", { required: true, pattern: /^[A-Za-z- ]+$/i })}
                               onChange={(e)=>props.manualHandleChange('lastname',e.target.value)}/>
                        {errors.lastname && errors.lastname.type === "required" && <span className="text-danger">Enter your last name</span>}
                        {errors.lastname && errors.lastname.type === "pattern" && <span className="text-danger">Only letters</span>}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 text-center mt-3 mb-3">
                        <button type="submit" className="btn-block btn btn-primary btn-lg btn-text-white">Next &gt;</button>
                        <button type="button" id="btnsubmit-debt-amount" className="btn-block btn btn-primary btn-lg btn-text-white btn-350" onClick={props.back}>{ 'Back' }</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default Name
