import React from "react";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";
import OptOut from "./OptOut";
import { useState } from "react";

const Footer = () => {
    const [ppIsOpen, setPpIsOpen] = useState(false);
    const dateYear = (new Date()).getFullYear();

    return <section id="footer">
        <footer>
            <div className="container">
                <div className="row mt-1 mb-3">
                    <div className="col-md-12 text-center">
                        <TermsOfUse setPpIsOpen={ setPpIsOpen } /> | <PrivacyPolicy ppIsOpen={ ppIsOpen } setPpIsOpen={ setPpIsOpen } /> | <a href="https://privacyportal-eu-cdn.onetrust.com/dsarwebform/942fbf02-bbfe-4a5d-808d-080b06c5651c/3fdf8f1d-7ab3-4a99-bb4e-24965500290d.html" target="_blank">Do Not Sell My Info</a> | <OptOut />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="disclaimer">
                            CutCreditCardDebt.us an RGR Marketing website is not a lender, broker, debt relief company, or other provider of financial or insurance products or services. The information you provide to us is not an application for a loan or other financial product or service. We do not use this information to pre-qualify you for a loan or financial products or services. We provide the information you give to us to third-party providers of these products and services. If the information you provide to us matches the criteria they are seeking, they will contact you directly. In many cases, we may deploy SMS to you to facilitate an inbound call to the service or product provider. We are paid by such providers for each consumer they contact directly and/or provide services or products. You are not charged for our services. We do not guarantee that a service provider or Lender will accept your registration information or that their products or services will meet your needs. Only the service provider can provide you with rates, terms, and products or services you have inquired about. It may not be the best product or service available on the market. Not all product or service providers will offer you the products that we advertise. Completing our forms does not obligate you to purchase a service or product nor does it obligate a product or service provider to provide you with any particular service about which you may have inquired. Available rates and terms in our marketing materials are subject to change without notice. We only accept referrals for U.S. Citizens on this Website and we specifically exclude all other countries including but not limited to Canadian and European Union Member Citizens referrals.
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-12 text-center copyright mb-1">&copy; {dateYear} CutCreditCardDebt. All rights reserved.</div>
                </div>
            </div>
        </footer>
    </section>
}

export default Footer
