import store from './state/store'
import { Provider } from 'react-redux'
import Form from "./Form";

function App() {
  return (
      <Provider store={store}>
        <Form/>
      </Provider>
  );
}

export default App;
