import React from 'react';
import logo from '../assets/images/logo.png'

const Header = () => {
    
    return <>
        <section id="header">
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mt-3 mb-3 logo">
                            <a href="/"><img alt="" src={logo} className="img-fluid"/></a>
                        </div>
                    </div>
                </div>
            </header>
        </section>

        <section id="progressbar" className="mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <div className="progress" style={{height:"10px"}}>
                            <div id="sectionProgress"
                                 className="progress-bar progress-bar-striped bg-info"
                                 role="progressbar"
                                 style={{width:"0%"}}
                                 aria-valuenow="0"
                                 aria-valuemin="0"
                                 aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Header
