import React, {useEffect} from "react";
import { useForm } from "react-hook-form";
import ReactGA from 'react-ga4';

const State = (props) => {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => props.next();

    useEffect(()=>{
        window.runeHawkTalon();

        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: "pageview", page: "/state" });

        ReactGA.event({
            category: 'Step 3, State',
            action: 'Step 3, State',
            label: 'Step 3, State'
        });
    },[])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
            <div id="section-state" className="section-question">
            <div className="row question">
                <div className="col-md-2"></div>
                <div className="col-md-8 text-center">
                    <p>What's your state?</p>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-md-4"></div>
                <div className="col-md-4 input-holder">
                    <select className="form-control" {...register("state", { required: true })} onChange={(e)=>props.manualHandleChange('state',e.target.value)} value={props.values.state}>
                        <option value="">Select State</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                    </select>
                    {errors.state && <span className="text-danger">Select your state</span>}
                </div>
            </div>

            <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4 text-center mt-3 mb-3">
                    <button type="submit" className="btn-block btn btn-primary btn-lg btn-text-white">Next &gt;</button>
                    <button type="button" id="btnsubmit-debt-amount" className="btn-block btn btn-primary btn-lg btn-text-white btn-350" onClick={props.back}>{ 'Back' }</button>
                </div>
            </div>
        </div>
        </form>
    )
}

export default State
