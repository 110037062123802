import React,{useEffect, useState} from "react";
import {PatternFormat} from "react-number-format";
import { useForm } from "react-hook-form";
import ReactGA from 'react-ga4';
import PartnerList from "../PartnerList";
import TermsOfUse from "../TermsOfUse";
import PrivacyPolicy from "../PrivacyPolicy";

const Contact = (props) => {
    const [ppIsOpen, setPpIsOpen] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    
    const onSubmit = data => props.next()

    useEffect(()=>{
        register('phone', {
            required: "Enter your phone number",
            maxLength: 10,
            minLength: 10,
            pattern: {
                value: /^[2-9]{1}[0-9]{2}[0-9]{3}[0-9]{4}$/,
                message: "Invalid phone number"
            }}
        );

        window.runeHawkTalon();

        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: "pageview", page: "/contact" });

        ReactGA.event({
            category: 'Step 5, Contact details',
            action: 'Step 5, Contact details',
            label: 'Step 5, Contact details'
        });
    },[])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
            <button type="button" id="btnsubmit-debt-amount" className="btn btn-primary btn-sm btn-text-black btn-350" onClick={props.back}>{ '' }Back</button>

            <div id="section-email-phone" className="section-question">
                <div className="row question">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 text-center">
                        <p>What's your contact details?</p>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 input-holder">
                        <input type="text" className="form-control" placeholder="Email Address" value={ props.values.email }
                               {...register("email", {
                                   required: "Please enter your email address",
                                   pattern: {
                                       value: /^[^\s@]+@[^\s.!-@#$%^&*();:,?¿/\\=+<>{}[\]~`'|°¬]+\.[^\s.!-@#$%^&*();:,?¿/\\=+<>{}[\]~`'|°¬]+$/,
                                       message: "Invalid email address"
                                   }
                               })}
                               onChange={(e)=>props.manualHandleChange('email',e.target.value)}/>
                        {errors.email && <span className="text-danger">{errors.email?.message}</span>}
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 input-holder">
                        <PatternFormat
                            className={`form-control`}
                            placeholder="(###) ###-####"
                            format="(###) ###-####"
                            mask="_"
                            defaultValue={props.values.phone}
                            onValueChange={(values, sourceInfo) => {
                                props.manualHandleChange('phone',values.value);
                                setValue('phone', values.value);
                            }}
                        />
                        {errors.phone && errors.phone.type === "required" &&<span className="text-danger">Enter your phone number</span>}
                        {errors.phone && errors.phone.type === "maxLength" &&<span className="text-danger">Please provide a 10-digit phone number</span>}
                        {errors.phone && errors.phone.type === "minLength" &&<span className="text-danger">Please provide a 10-digit phone number</span>}
                        {errors.phone && errors.phone.type === "pattern" &&<span className="text-danger">Please don't start Phone Number with 1 or 0</span>}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4 text-center mt-3 mb-3">
                        <label id="leadid_tcpa_disclosure_label" htmlFor="leadid_tcpa_disclosure">
                            <div className="mt-2 mb-2" style={{fontSize:13}}>
                                <input type="hidden" id="leadid_tcpa_disclosure" />By clicking the button below, you acknowledge, consent, and agree to our terms at the bottom of this page.
                            </div>
                            <input type="submit" className="btn-block btn btn-primary btn-lg btn-text-white" value="GET MY FREE QUOTE"/>
                            <div className="mt-2" style={{fontSize:13}}>
                                By clicking "GET MY FREE QUOTE" you authorize up to four <PartnerList/> to call you (including through automated means; e.g. autodialing, text and pre-recorded messaging) via telephone, mobile device (including SMS and MMS) and/or email, at the number you entered above, with offers about their products or services, even if your phone number is on any national or state "Do Not Call" list and you agree to our <TermsOfUse/> and <PrivacyPolicy ppIsOpen={ ppIsOpen } setPpIsOpen={ setPpIsOpen } />. Message and data rates may apply. Your consent here is not based on a condition of purchase.
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default Contact
