import React,{useEffect} from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import './assets/css/bootstrap.css';
import './assets/css/percircle.css';
import './assets/css/style.css';
import DebtAmount from "./components/formsteps/DebtAmount";
import queryString from 'query-string';
import {useDispatch, useSelector} from "react-redux";
import {actionForm} from "./state/actions";
import Final from "./components/formsteps/Final";
import BehindPayments from "./components/formsteps/BehindPayments";
import State from "./components/formsteps/State";
import Name from "./components/formsteps/Name";
import Contact from "./components/formsteps/Contact";

const Form = () => {
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!state.publisher_id) {
            const getParameters = queryString.parse(window.location.search);
            let publisher_id, rcid, subid, cake;

            if (getParameters.publisher_id) {
                publisher_id = getParameters.publisher_id
            } else {
                publisher_id = 1;
            }

            if (getParameters.rcid) {
                rcid = getParameters.rcid
            }else {
                rcid = "";
            }

            if (getParameters.subid) {
                subid = getParameters.subid
            }else {
                subid = "";
            }

            if (getParameters.cake) {
                cake = getParameters.cake
            }else {
                cake = "";
            }

            manualHandleChange('publisher_id', publisher_id);
            manualHandleChange('rcid', rcid);
            manualHandleChange('subid', subid);
            manualHandleChange('cake', cake);
        }
    },[]);

    const manualHandleChange = (name,val) => {
        dispatch(actionForm({
            [name]: val
        }))
    }

    const next = () => {
        dispatch(actionForm({
            step: state.step + 1
        }));
    }

    const back = () => {
        dispatch(actionForm({
            step: state.step - 1
        }));
    }

    const openMainPage = () => {
        dispatch(actionForm({
            step: 1
        }));
        window.scrollTo(0, 0);
    }

    const handleChange = input => e => {
        dispatch(actionForm({
            [input]: e.target.value
        }))
    }

    const handleSubmit = () =>{
        dispatch(actionForm({
            signupSuccess: true
        }))
        next()
    }

    const renderStep = (step) => {

        switch (step) {
            case 1:
                return (
                    <DebtAmount
                        values = {state}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        openMainPage = {openMainPage}
                    />
                )
            case 2:
                return (
                    <BehindPayments
                        values = {state}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        openMainPage = {openMainPage}
                    />
                )
            case 3:
                return (
                    <State
                        values = {state}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        openMainPage = {openMainPage}
                    />
                )
            case 4:
                return (
                    <Name
                        values = {state}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        openMainPage = {openMainPage}
                    />
                )
            case 5:
                return (
                    <Contact
                        values = {state}
                        handleChange = {handleChange}
                        next = {next}
                        back = {back}
                        manualHandleChange = {manualHandleChange}
                        openMainPage = {openMainPage}
                    />
                )
            case 6:
                return (
                    <Final
                        values = {state}
                        handleChange = {handleChange}
                        next = {next}
                        handleSubmit = {handleSubmit}
                        openMainPage = {openMainPage}
                    />
                )
        }
    }

    return (
        <>
            <Header/>
            <div className="container">
                {state.step &&
                    <>{renderStep(state.step)}</>
                }
            </div>
            <Footer/>
        </>
    )
}

export default Form
