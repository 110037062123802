const initialState = {
    step: 1,
    signupSuccess: false
};

function rootReducer(state = initialState, action) {
    if (action.type === 'FORM') {
        return Object.assign({}, state, action.payload);
    }
    return state;
}

export default rootReducer;
