import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import parse from "html-react-parser";

export default function PartnerList(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [partners, setPartners] = useState();

    useEffect(() => {
        fetch('https://api.reallygreatrate.com/landingpagecontent/tcpa-partners-list-debt', {
            method: 'POST',
            body: JSON.stringify({ sitename:"CutCreditCardDebt.us" }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
            })
             .then((response) => response.json())
             .then((resp) => {
                setPartners(resp.data.html);
            })
             .catch((err) => {
                console.log(err.message);
             });
    }, []);

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <a href="javascript:void();" onClick={toggleModal}>partners</a>
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="Partner list"
                className="contentModal"
                overlayClassName="contentModalOverlay"
                ariaHideApp={false}
                partners={partners}
            >
                <div className="contentModalHeader">
                    <div className="row mt-2">
                        <div className="col">
                            <h3>DEBT PARTNERS</h3>
                        </div>
                        <div className="col-auto text-right">
                            <a className="pointer-cursor text-white" onClick={toggleModal}>Close</a>
                        </div>
                    </div>
                </div>
                <div className="contentModalText">
                    {
                        partners && parse(partners)
                    }
                </div>
            </Modal>
        </>
    );
}
