import React, { useEffect, useState } from "react";

import LoadingOverlay from "react-loading-overlay";
import ReactGA from "react-ga4";
import axios from "axios";
import parse from "html-react-parser";

const Final = (props) => {
  const [sent, setSent] = useState(false);
  const [sentResult, setSendResult] = useState(false);
  const iframeStyles = { border: "none", display: "block" };
  const tcpaLabel = document.getElementById('leadid_tcpa_disclosure_label');

  useEffect(() => {
    if (!sent) {
      setSent(true);

      const debtarray = [
        4500, 
        7500, 
        12500, 
        17500, 
        22500, 
        27500, 
        35000, 
        45000,
        55000,
        67500,
        75000
    ];
      const anura_json = sessionStorage.getItem("anura_final_response");
      const tcpa = tcpaLabel.textContent || '';

      const data = {
        publisher_id: props.values.publisher_id,
        type: "debtsettlement",
        source: process.env.REACT_APP_SOURCE,
        creditcarddebt: debtarray[props.values.creditcarddebt],
        behindonpayments: props.values.behindonpayments,
        firstname: props.values.firstname,
        lastname: props.values.lastname,
        phone: props.values.phone,
        email: props.values.email,
        state: props.values.state,
        leadidtoken: window.leadId,
        trustedformtoken:
          "https://cert.trustedform.com/" + window.trustedFormId,
        anura: anura_json,
        tcpa,
      };

      if (props.values.rcid) {
        data.rcid = props.values.rcid;
      }

      if (props.values.subid) {
        data.subid = props.values.subid;
      }

      if (props.values.cake) {
        data.cake = props.values.cake;
      }

      axios
        .post(process.env.REACT_APP_API_URL + "/lead/web", data)
        .then(function (response) {
          setSendResult(response.data);
        });

      ReactGA.initialize(process.env.REACT_APP_GA);
      ReactGA.send({ hitType: "pageview", page: "/submit" });

      // ReactGA.pageview('/submit');

      ReactGA.event({
        category: "CCCD Conversion",
        action: "CCCD Conversion",
        label: "CCCD Conversion",
      });
    }
  }, [sent]);

  return (
    <>
      <LoadingOverlay
        active={!sentResult}
        spinner
        styles={{
          wrapper: {
            width: "100%",
            height: "100%",
            overflow: !sentResult ? "hidden" : "scroll",
          },
        }}
      />
      {sentResult && (
        <div className="section-question">
          {sentResult.status === "matched" ? (
            <>
              <div className="wrapper">
                <h3 className="thanks-text">Thank you for your request.</h3>
                <h2 className="congrats-text">
                  Congratulations, {props.values.firstname}! You are one step closer to being debt free!
                  Our partner below will call you shortly.
                </h2>
                {sentResult.lenders && (
                  <div className="row aligned-items">
                    {sentResult.lenders[0].logo && (
                      <div
                        id="lender-logo"
                        className="col-sm-12 col-md-4 text-center"
                      >
                        <img
                          src={sentResult.lenders[0].logo}
                          alt="company logo"
                        />
                      </div>
                    )}
                    <div className="col-sm-12 col-md-8 text-center">
                      {sentResult.lenders[0].hideTitle === "no" && (
                        <h3 className="title">{sentResult.lenders[0].name}</h3>
                      )}
                      {sentResult.lenders[0].bulletpoints && (
                        <div className="text-left bulletpoints">
                          {parse(sentResult.lenders[0].bulletpoints)}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {sentResult.lenders &&
                sentResult.lenders[0].redirectUrl &&
                sentResult.lenders[0].redirectUrl !== "" && (
                  <iframe
                    id="chatUrl"
                    width="100%"
                    height="900"
                    style={iframeStyles}
                    src={sentResult.lenders[0].redirectUrl}
                  ></iframe>
                )}
            </>
          ) : (
            <>
              <div className="wrapper">
                <h2 className="congrats-text">
                  <span>Congratulations, {props.values.firstname}!</span>
                  <br />
                  <br />
                  We've received your request and have matched you with a
                  Trusted Provider that specializes in Credit Card Debt
                  Solutions. You should receive a call within the next few
                  minutes so you can get connected. If you are unavailable, a
                  confirmation text will be sent, so connecting at your
                  convenience is quick and easy. We look forward to assisting
                  you!
                </h2>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Final;
