import React,{useEffect} from "react";
import ReactGA from 'react-ga4';
import classNames from "classnames";

const BehindPayments = (props) => {

    const selectOption = (option) => {
        props.manualHandleChange('behindonpayments',option);
        props.next();
    }

    useEffect(()=>{
        window.runeHawkTalon();

        ReactGA.initialize(process.env.REACT_APP_GA);
        ReactGA.send({ hitType: "pageview", page: "/behind-payments" });

        ReactGA.event({
            category: 'Step 2, Behind payments',
            action: 'Step 2, Behind payments',
            label: 'Step 2, Behind payments'
        });
    },[])

    return (
        <form>
            <input type="hidden" name="universal_leadid" value="" id="leadid_token"/>
            <div id="section-behind-your-payments" className="section-question">
                <div className="row question">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 text-center">
                        <p>Are you behind on your payments?</p>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-md-1"></div>
                    <div className="col-md-10">
                        <ul className="behind-your-payments">
                            <li className={ classNames('yes-60', {'selected-behind-payment': props.values.behindonpayments === 61}) } onClick={()=>selectOption(61)}>Yes, more than 60 days</li>
                            <li className={ classNames('yes-30', {'selected-behind-payment': props.values.behindonpayments === 31}) } onClick={()=>selectOption(31)}>Yes, more than 30 days</li>
                            <li className={ classNames('no', {'selected-behind-payment': props.values.behindonpayments === 0}) } onClick={()=>selectOption(0)}>No</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4 text-center mt-3 mb-3">
                    <button type="button" id="btnsubmit-debt-amount" className="btn-block btn btn-primary btn-lg btn-text-white btn-350" onClick={props.back}>{ 'Back' }</button>
                </div>
            </div>
        </form>
    )
}

export default BehindPayments
