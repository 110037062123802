import React, { useState,useEffect } from "react";
import Modal from "react-modal";
import parse from 'html-react-parser'
import axios from "axios"

export default function TermsOfUse({ setPpIsOpen }) {
    const [isOpen, setIsOpen] = useState(false);
    const [content,setContent] = useState(null)

    function toggleModal() {
        setIsOpen(!isOpen);
    }

    useEffect(()=>{
        axios.post(process.env.REACT_APP_API_URL + "/landingpagecontent/terms-of-use-debt-settlement", {sitename:"CutCreditCardDebt.Us"})
            .then(function (response) {
                setContent(response.data.data.html);
            })
    },[])

    function handleClick($event) {
        if ($event.target.hash === '#privacy-policy') {
            setIsOpen(false);
            setPpIsOpen(true);
        }
    }

    const customStyles = {
        content: {
          width: '90%',
          height: '70%',
        },
      };

    return (
        <>
            <a href="javascript:void();" onClick={toggleModal}>Terms of Use</a>
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal}
                contentLabel="Terms of use"
                className="contentModal"
                overlayClassName="contentModalOverlay"
                ariaHideApp={false}
                style={customStyles}
            >
                <div className="contentModalHeader">
                    <div className="row mt-2">
                        <div className="col">
                            <h3>TERMS OF USE</h3>
                        </div>
                        <div className="col-auto text-right">
                            <a className="pointer-cursor text-white" onClick={toggleModal}>Close</a>
                        </div>
                    </div>
                </div>
                <div className="contentModalText" onClick={ ($event) => { handleClick($event) } }>
                    {content &&
                        parse(content)
                    }
                </div>
            </Modal>
        </>
    );
}
